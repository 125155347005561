var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-table-container px-0 mx-0 mb-2"},[(_vm.items.length)?_c('b-table',_vm._g({staticClass:"table-list mb-0",attrs:{"responsive":"","fixed":"","no-local-sorting":"","id":"notification-table","items":_vm.items,"fields":_vm.fields,"sort-by":_vm.queryParams.sortBy,"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.created)))]}},{key:"cell(created_by)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdByName)+" ")]}},{key:"cell(number)",fn:function(ref){
var item = ref.item;
return [_c('custom-router-link',{attrs:{"to":{
                    name: 'ReserveDetails',
                    params: {orderId: item.orderHeaderId.toString()},
                }}},[_vm._v(_vm._s(item.orderHeaderId))])]}},{key:"cell(patient_id)",fn:function(ref){
                var item = ref.item;
return [_c('custom-router-link',{attrs:{"to":{
                    name: 'PatientDetails',
                    params: {patientId: item.patientId},
                }}},[_vm._v(" "+_vm._s(item.ocosPatientId)+" ")])]}},{key:"cell(status)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReservationOrderStatus(item.reservationStatus, { isConsignment: _vm.isConsignment(item), }))+" ")]}},{key:"cell(surgeon)",fn:function(ref){
                var item = ref.item;
return [_c('custom-router-link',{attrs:{"to":{
                    name: 'SurgeonDetails',
                    params: {doctorId: item.surgeonId},
                },"disabled":!(item.surgeonId > 0)}},[_vm._v(" "+_vm._s(item.contactPartyNumber)+" - "+_vm._s(item.surgeonName)+" ")])]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
return [_c('b-icon',{staticClass:"pointer",attrs:{"icon":"trash","scale":"1.5"},on:{"click":function($event){return _vm.$emit('change-status', item)}}})]}}],null,false,2162308967)},_vm.$listeners)):_c('section',{staticClass:"text-center heavy text-gray-dark h4"},[_c('b-icon',{staticClass:"my-4",attrs:{"icon":"bell","scale":"2"}}),_c('p',[_vm._v(_vm._s(_vm.t('noNotification')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }